import Cookies from 'js-cookie'
import { getLocale, translations } from 'middlewares/localizer'
import { parse } from 'query-params-helpers'
import { boundReducer } from 'redux-upgrader'
import * as C from './consts'

const defaultLocale = getLocale()

const defaults = {
  locale: defaultLocale,
  // strings: translations[defaultLocale] || translations['en'],
  strings: translations[defaultLocale],
  listViewTypes: ['grid', 'list'],
  viewType: Cookies.get('list_view_type') || 'grid',
}

export const requestInfo = boundReducer(
  {},
  {
    SET_REQUEST_INFO: (_state, action) => action.value,
  }
)

export const isMobile = boundReducer(false, {
  SET_IS_MOBILE: (_state, action) => action.value,
})

export const isIE = boundReducer(false, {
  SET_IS_IE: (_state, action) => action.value,
})

export const mobileMenuOpen = boundReducer(false, {
  [C.TOGGLE_MOBILE_MENU]: (state, action) => !state,
})

export const mobileFilterOpen = boundReducer(false, {
  [C.TOGGLE_MOBILE_FILTER]: (state, action) => !state,
})

export const strings = boundReducer(defaults.strings, {
  [C.SET_STRINGS]: (state, action) => action.strings,
})

export const locale = boundReducer(defaults.locale, {
  [C.SET_LOCALE]: (state, action) => action.locale,
})

export const listViewTypes = boundReducer(defaults.listViewTypes, {})

export const listViewType = boundReducer(defaults.viewType, {
  [C.SET_LIST_VIEW_TYPE]: (state, action) => action.value,
})

export const cardWarningModal = boundReducer(false, {
  [C.OPEN_CARDWARNING_MODAL]: true,
  [C.CLOSE_CARDWARNING_MODAL]: false,
})

export const signInModal = boundReducer(false, {
  [C.OPEN_SIGNIN_MODAL]: (state, action) => ({
    isOpen: true,
    email: action.email,
  }),
  [C.OPEN_SIGNUP_MODAL]: false,
  [C.CLOSE_SIGNIN_MODAL]: false,
})

export const signUpModal = boundReducer(false, {
  [C.OPEN_SIGNUP_MODAL]: true,
  [C.OPEN_SIGNIN_MODAL]: false,
  [C.CLOSE_SIGNUP_MODAL]: false,
})

export const clearPage = boundReducer(false, {
  [C.CLEAR_PAGE]: (state, action) => action.value,
})

export const landingPage = boundReducer(false, {
  [C.LANDING_PAGE]: (state, action) => action.value,
})

export const IsFooterSocials = boundReducer(false, {
  [C.FOOTER_SOCIALS]: (state, action) => action.value,
})

export const wrapClass = boundReducer(null, {
  [C.WRAP_CLASS]: (state, action) => action.value,
})

export const notFound = boundReducer([], {
  [C.DEFINE_NOT_FOUND]: (state, action) => state.concat(action.path),
  [C.RESTORE_NOT_FOUND]: (state, action) =>
    state.filter(url => url !== action.path),
})

export const newsletterModal = boundReducer(false, {
  [C.OPEN_NEWSLETTER_MODAL]: true,
  [C.CLOSE_NEWSLETTER_MODAL]: false,
})

export const tosModal = boundReducer(false, {
  [C.OPEN_TOS_MODAL]: true,
  [C.CLOSE_TOS_MODAL]: false,
})

export const newListModal = boundReducer(false, {
  [C.OPEN_NEW_LIST_MODAL]: true,
  [C.CLOSE_NEW_LIST_MODAL]: false,
})

export const renameListModal = boundReducer(false, {
  [C.OPEN_RENAME_LIST_MODAL]: true,
  [C.CLOSE_RENAME_LIST_MODAL]: false,
})

export const AuthModal = boundReducer(false, {
  [C.OPEN_AUTH_MODAL]: true,
  [C.CLOSE_AUTH_MODAL]: false,
})

export const duplicateModal = boundReducer(false, {
  [C.OPEN_DUPLICATE_MODAL]: true,
  [C.CLOSE_DUPLICATE_MODAL]: false,
})

export const verifyModal = boundReducer(false, {
  [C.OPEN_VERIFY_MODAL]: true,
  [C.CLOSE_VERIFY_MODAL]: false,
})

export const cookiesAccepted = boundReducer(
  {},
  {
    [C.SET_COOKIES_ACCEPTED]: (state, action) => action.cookies || state,
  }
)

export const searchParams = boundReducer(
  {},
  {
    '@@router/LOCATION_CHANGE': (state, action) => {
      const { location } = action.payload
      const { prevPage = '' } = location.state || {}
      const isVideos = path =>
        /\w{2}\/videos($|\/(tags|category|country|region|filter)\/)/g.test(path)

      if (!isVideos(location.pathname) && isVideos(prevPage)) {
        return {}
      }

      let parsed = {}
      if (action.payload.location.search) {
        parsed = parse(decodeURIComponent(action.payload.location.search))
      }

      if (parsed.with_international_mix) {
        Cookies.set('with_international_mix', true)
      } else {
        Cookies.remove('with_international_mix')
      }

      if (action.searchIsChanged) {
        return parsed
      } else {
        return state
      }
    },
    'SET_SEARCH_PARAMS': (state, action) => {
      return action.params || {}
    },
  }
)

export const pagination = boundReducer(
  {
    page: 1,
    pages: [1],
  },
  {
    '@@router/LOCATION_CHANGE': (state, action) => {
      let parsed = {}
      if (action.payload.location.search) {
        parsed = parse(action.payload.location.search)
      }
      if (!parsed.hasOwnProperty('page')) {
        parsed.page = 1
      }
      if (!parsed.hasOwnProperty('pages')) {
        parsed.pages = [parsed.page]
      }
      return {
        page: parsed.page,
        pages: parsed.pages,
      }
    },
    'SET_PAGINATION': (state, action) => {
      return action.params
    },
  }
)

export const hashParams = boundReducer(
  {},
  {
    '@@router/LOCATION_CHANGE': (state, action) => {
      return parse(action.payload.location.hash) || state
    },
  }
)

export const toggleWebpushSettingsPopup = boundReducer(false, {
  [C.TOGGLE_WEBPUSH_SETTINGS_POPUP]: (state, action) => !state,
})
